import { createRouter, createWebHistory } from "vue-router";
import UsuarisLlistaView from "../usuaris/views/UsuarisLlistaView.vue";
import LoginView from "../usuaris/views/LoginView.vue";
import { useAuthUserStore } from "@/store/authUser";
//import usuarisFitxa from '../usuaris/views/usuarisFitxa.vue'
import HomeView from "../usuaris/views/HomeView.vue";
import UsuarisFitxaView from "../usuaris/views/UsuarisFitxaView";
import FitxarView from "../usuaris/views/FitxarView.vue";
import ClientsLlistaView from "../clients/views/ClientsLlistaView.vue"


const routes = [
 
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },

  {
    path: "/",
    name: "homeView",
    component: HomeView,
    meta: { requireAuth: true },
  },

  {
    path: "/fitxar",
    name: "fitxar",
    component: FitxarView,
    meta: { requireAuth: true },
  },

  {
    path: "/usuaris/:id",
    name: "usuarisFitxa",
    component: UsuarisFitxaView,
    meta: { requireAuth: true },
  },

  {
    path: "/usuaris",
    name: "usuarisllista",
    component: UsuarisLlistaView,
    meta: { requireAuth: true },
  },

  {
    path: "/clients",
    name: "clientsllista",
    component: ClientsLlistaView,
    meta: { requireAuth: true },
  },
  // {
  //   path: '/usuaris/:id',
  //   name: 'usuariFitxa',
  //   component: usuarisFitxa
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass:'active'
});

router.beforeEach((to, from, next) => {
  const authUserStore = useAuthUserStore();
  if (to.meta.requireAuth && !authUserStore.isLoggedIn()) {
    authUserStore.returnUrl = to.fullPath;
    next({ name: "Login" });
  } else next();
});

export default router;
