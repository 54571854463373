<template>
  <div class="container-fluid">
    <div class="capcalera">
      <h4>Gestió equip</h4>
      <button
        class="btn btn-outline-secondary btn-sm"
        @click="handleLayout(LlistatUsuarisTable)"
      >
        Llista
      </button>
      <button
        class="btn btn-outline-secondary btn-sm"
        @click="handleLayout(LlistatUsuarisCard)"
      >
        Tarjeta
      </button>
    </div>

    <div class="container-component">
      <div class="btn_filtrar">
        <a
          class="btn btn-outline-secondary btn-sm"
          data-bs-toggle="collapse"
          href="#collapseFilterAdvanced"
          role="button"
          aria-expanded="false"
          aria-controls="collapseFilterAdvanced"
        >
          Filtrar per camp
        </a>
      </div>
      <div class="collapse" id="collapseFilterAdvanced">
        <div class="card card-body mb-5">
          <FilterAdvanced
            :columns="gridColumns"
            :filter="filter"
            @aplyFilter="aplyFilter"
          />
        </div>
      </div>

      <component
        :is="viewComponent"
        :result="result"
        :columns="gridColumns"
        :filter="filter"
        @aplyOrder="aplyOrder"
        @aplyFilter="aplyFilter"
        @draggableOrder="draggableOrder"
        
      />
    </div>

  </div>
</template>

<script setup>
import FilterAdvanced from "../../components_generics/FilterAdvanced.vue";

import { ref, onMounted, defineAsyncComponent,shallowRef } from "vue";

const LlistatUsuarisCard = defineAsyncComponent(() =>
  import("../components/LlistatUsuarisCard.vue")
);
const LlistatUsuarisTable = defineAsyncComponent(() =>
  import("../components/LlistatUsuarisTable.vue")
);

//------COMPONENTS DINÀMICS------

const viewComponent = shallowRef(LlistatUsuarisTable);

const handleLayout = (component) => (viewComponent.value = component);

//------VARIABLES------

const URL = "https://api.demo.lluert.net/users/";
const result = ref([]);
const orderBy = ref("");
const limit = ref(25);
const apiKey = "iejlLKypoD5LBzUwMBeH";
const error = ref(null);
let filter = { actiuUsuari: 1 };
//const search = ref(null);

const gridColumns = [
  {
    name: "Usuari",
    key: "nomUsuari",
    type: "text",
  },
  {
    name: "Direcció",
    key: "direccioUsuari",
    type: "text",
  },
  {
    name: "Email",
    key: "emailUsuari",
    type: "text",
  },
  {
    name: "Actiu",
    key: "actiuUsuari",
    type: "select",
    options:[
      {
        name: 'Tots',
        value: "*"
      },
      {
        name: 'Actiu',
        value: 1
      },
      {
        name: 'No actiu',
        value: 0
      },

    ]

  },
];


//------FUNCIONS------


const getData = async () => {
  const params = {
    campOrdre: orderBy.value,
    limit: limit.value,
  };

  const searchFilter = { filter: JSON.stringify(filter) };
  result.value = [];
  error.value = null;
  const searchParams = new URLSearchParams({
    ...params,
    ...searchFilter,
  }).toString();

  try {
    const response = await fetch(`${URL}?${searchParams}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      
    });
    // if (!response.ok) throw new Error("user not found")
    const data = await response.json();
    result.value = data.resultat;
    //console.log(result.value);
  } catch (error) {
    error.value = error;
  }
};

onMounted(() => {
  getData();
});

const draggableOrder = (resultat) => {
  result.value=resultat
}

const aplyOrder = (campOrdre, order) => {
  orderBy.value = `${campOrdre} ${order}`;
  getData();
};

const aplyFilter = (filterTMP) => {
  filter = filterTMP;
  getData();
};

</script>

<style scoped>



.capcalera {
  border-bottom: 0.5px gray solid;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  width: 100%;
  gap: 15px;
}

.btn_filtrar {
  text-align: right;
  margin-bottom: 20px;
}

.container-component {
  padding: 20px;
  background-color: #f8f9fa;
  height: 100vh;
}
</style>
