<template>
    <div class='filtrar_inputs'>
        <div v-for="column in props.columns" :key="column.name">
            <div v-if="column.type === 'text'">
            <label>{{column.name}}</label>
            <input class="form-control" v-model="filterLocal[column.key]" type="text" style="width:150px"  />
            
            </div>
            <div v-if="column.type === 'select'">
            <label>{{column.name}}</label>
            <select class="form-select" v-model="filterLocal[column.key]" style="width:150px">
                    <option v-for="item in column.options" :key="item" :value="item.value">{{item.name}}</option>
            </select>
            </div>       
         
         </div>
        <div>
            <button @click="filterByField(filterLocal)"  class='btn btn-outline-success form-control buto_filtrar_camps' style="width:150px">Filtrar</button>
        </div>
    </div>
</template>

<script setup>
import {defineProps,defineEmits} from 'vue'


let filterLocal = {}



const props = defineProps(['columns','filter'])

const emit = defineEmits(['aplyFilter'])

const filterByField = ( filter) => {
    console.log(filter)
    emit('aplyFilter', filter)
}

//props.columns.map(item => console.log(item.key))
</script>

<style scoped>
.filtrar_inputs{
    display:flex;
    justify-content: end;
    align-items: center;
    flex-wrap: wrap;
    gap:20px;
}
.filtrar_inputs button{
    margin-top:20px;
}
</style>