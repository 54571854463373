import { defineStore } from 'pinia'
import router  from '@/router';
import { apiFech } from '@/composables/apiFetch';
const baseUrl = process.env.VUE_APP_API_URL_BASE+`/users`;


export const useAuthUserStore = defineStore({
    id: 'authUser',
    state: ()=>({        
            user: JSON.parse(localStorage.getItem('user')),
            returnUrl: null,  //per si no estas autenticat la guardarem per redireccionar-te
            error: null,
    }), 
    getters: {
        getError(state){ return state.error}
    },   
    actions:{
         async login(username, password) {
            const user =  await apiFech.post(`${baseUrl}/authenticate`, { username, password });
            
            if(user.status=='ok' && user.result){
                this.user = user.result
                localStorage.setItem('user', JSON.stringify(user.result));
                // redirect to previous url or default to home page
                router.push( this.returnUrl || '/home')
            }else{ 
                console.log('entro error')
                console.log(user.error.msgError)
                this.error = user.error.msgError
                return false
            }
            
        },
        isLoggedIn() {            
            if(this.user!==null){                
                if(this.user.apiKey) return true;                        
            }
            return false
        },
            

        logout(){
            this.user = null;
            localStorage.removeItem('user')
            router.push('/login')
        }

    }


})