
<template>
<div id="button_menuFlotant">
   <a class="btn btn-outline-secondary " @click="mostrarMenu" href="#">
      <i class="bi bi-list"></i>
   </a>
</div>
    <nav id="sideMenu" v-show="authUserStore.isLoggedIn()"> 
        <!-- <SideBar /> -->
        <div id="sideMenuHead">
        <figure style="text-align: center;">
            <img src="../../src/assets/img/lluert_white.png" alt="logo">
        </figure>
        <div id="botonLogo" style="float:right;">
            <a class="btn noDisabled " @click="amagarMenu">
                <i class="bi bi-chevron-double-left text-light"></i>
            </a>
        </div>
        </div>

        <div id="sideMenuBody">
        <ul>
            <li class="nav-item ">
                <router-link :to="{name: 'homeView'}" class="noDisabled" style="background-color: transparent;" href="">
                <i class="bi bi-house-fill me-1"></i>
                <span>Inici</span>
                </router-link> 
            </li>
            <li class="nav-item menu ">
                <router-link :to="{name: 'fitxar'}">
                <i class="bi bi-clock-fill me-1"></i>
                <span>Fitxar</span>
                </router-link> 
            </li>
            
            <li class="nav-item menu">
                <a  class="fletxaDown collapse collapsed in"  href="#menu_configuracio" data-bs-toggle="collapse" aria-expanded="false" role="button" aria-controls="menu_configuracio" >
                    <i class="bi bi-person-fill me-1"></i>
                    <span>Configuració sistema</span>
                    <i class ="bi bi-chevron-down ms-4 "></i>
                
                </a>
                <div class="noDisabled collapse show" id="menu_configuracio"  data-parent="#sideMenuBody">
                <ul>
                    <li id="subMenu_Usuaris"><router-link class="noDisabled" :to="{name: 'usuarisllista'}"><i class="bi bi-chevron-right me-1"></i><span>Equip</span></router-link> </li>
                </ul>
                </div>
            </li>


            <li class="nav-item menu">
                <a  class="fletxaDown collapse collapsed in"  href="#menu_clients" data-bs-toggle="collapse" aria-expanded="false" role="button" aria-controls="menu_clients"  >
                    <i class="bi bi-person-fill me-1"></i>
                    <span>Clients</span>
                    <i class ="bi bi-chevron-down ms-4 "></i>
                
                </a>
                <div class="noDisabled collapse show" id="menu_clients"  data-parent="#sideMenuBody">
                <ul>
                    <li id="subMenu_Usuaris"><router-link class="noDisabled" :to="{name: 'clientsllista'}"><i class="bi bi-chevron-right me-1"></i><span>Llistar clients</span></router-link> </li>
                </ul>
                </div>
            </li>



            
            
        </ul>
        </div>
    
        <button @click="authUserStore.logout()">logout</button>
    </nav>

  </template>


  <script setup>

 import {useAuthUserStore }from '@/store/authUser'
 const authUserStore = useAuthUserStore();

const amagarMenu = () => {
    document.querySelector("body").classList.toggle("menuFlotant")

}

const mostrarMenu = () => {
    document.querySelector("body").classList.toggle("menuFlotant")
 
  
}

</script>

<style scoped src="../css/estils_estructura.css">




</style>