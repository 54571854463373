<template>

  <div class="container-fluid main-view">
    <div class="capcalera">
      <h4>{{result.nomUsuari}}</h4>
    </div >

    <div class="navbar navbar-expand-lg menuVistes">
      <ul class="nav justify-content-start">
      <li class="nav-item" @click="handleLayout(Dades)">Dades personals</li>
      <li class="nav-item" @click="handleLayout(Horaris)">Horaris</li>
      <li class="nav-item" @click="handleLayout(Nomines)">Nómines</li>
      <li class="nav-item" @click="handleLayout(Contracte)">Contracte Laboral</li>
      <li class="nav-item" @click="handleLayout(Calendari)">Calendari Laboral</li>
      <li class="nav-item" @click="handleLayout(Fitxatges)">Fitxatges</li>
    </ul>


    </div>
    
<div class="container-component">
  <component :is="viewComponent"
              :result="result" 
              @emit="emit"/>
</div>
  
  </div>
</template>

<script setup>
import { defineAsyncComponent, shallowRef,onMounted,ref } from "vue";
import { useRoute} from "vue-router";

//------COMPONENTS DINÀMICS------

const Dades = defineAsyncComponent(() => import("../views/DadesView.vue"));

const Horaris = defineAsyncComponent(() => import("../views/HorarisView.vue"));

const Nomines = defineAsyncComponent(() => import("../views/NominesView.vue"));

const Contracte = defineAsyncComponent(() => import("../views/ContracteView.vue")
);

const Calendari = defineAsyncComponent(() => import("../views/CalendariView.vue")
);

const Fitxatges = defineAsyncComponent(() => import("../views/FitxatgesView.vue")
);

const viewComponent = shallowRef(Dades);

const handleLayout = (component) => (viewComponent.value = component);

//------VARIABLES------

const URL = "https://api.demo.lluert.net/users/";
const apiKey = "iejlLKypoD5LBzUwMBeH";
const error = ref(null);
const userId = ref("");
const result = ref([]);



//-------CRIDA A API-------

const getData = async () => {
  const params = {
    idUsuari: userId.value,
  };
  result.value = [];
  error.value = null;
  const searchParams = new URLSearchParams(params).toString();
  //console.log(searchParams);
  try {
    const response = await fetch(`${URL}?${searchParams}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
    });
    // if (!response.ok) throw new Error("user not found")
    const data = await response.json();
    result.value = data.resultat[0];
    //console.log(result);
  } catch (error) {
    error.value = error;
  }
};

//------FUNCIONS

onMounted(() => {
  const route = useRoute();
  userId.value = route.params.id;
  getData();
});


const emit =() => {
  alert('emit desde pare')
}


</script>

<style scoped >

.capcalera {
  display: flex;
  flex-direction: column;

  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  width: 100%;
}

.capcalera h4{
  margin-left:50px;
}

.container-component {
  padding: 20px;
  background-color: #f8f9fa;
  height: 100vh;
}

ul {
  display: flex;
  justify-content: start;
  list-style: none;
  gap:20px;
  border-bottom: 0.5px gray solid;
}

li{
  margin-left:0;
}
</style>

