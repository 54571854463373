<template>
<div>
    <div class="main-view">home</div>




</div>
    
</template>



<script>

</script>

<style scoped>


</style>